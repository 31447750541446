<template>
  <v-container>
    <row-header action="non" :msg="msgRowHeader"></row-header>

    <v-row dense>
      <v-col>
        <v-form
            ref="form"
            v-model="valid"
        >
          <v-card>
            <tf-balance-form-component
                :user_model="user_model"
                :user_list="user_list"
                :amount="amount"
                :current_balance="current_balance"
                update="0"
            />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  :disabled="!valid"
                  color="success"
                  @click="saveData"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <v-overlay
        :absolute="absolute"
        :value="overlay"
    >
      <v-card color="white">
        <v-card-text>
          <overlay-img></overlay-img>
        </v-card-text>
      </v-card>
    </v-overlay>

  </v-container>
</template>

<script>
import RowHeader from "../../../components/content/RowHeader";
import OverlayImg from "../../../components/content/OverlayImg";
import TfBalanceFormComponent from '../../../components/page/tf-balance/TfBalanceFormComponent';
import { GetRequest, PostRequest } from "../../../store/services/users";

export default {
  components: {
    RowHeader,
    OverlayImg,
    TfBalanceFormComponent,
  },
  data:() => ({
    msgRowHeader: {newUser: 'Transfer Balance', newMsg: 'Transfer Balance', actionName: 'non'},
    valid: true,
    absolute: false,
    overlay: false,
    user_model: {},
    user_list: [],
    amount: 0,
    current_balance: 0,
  }),
  mounted() {
    if (this.$store.getters.isLoggedIn) {
      if (this.$store.getters.currentUser.role.level === 2) {
        console.log('tf balance new page');
        // this.user_model.amount = 0;
        this.getUserData();
        this.getUserDataCurrent();

        this.action.$on('change-tf-balance-to-user', async (val) => {
          if (val) {
            await this.changeUserHandler(val);
          }
        });

        this.action.$on('change-tf-balance-amount', async (val) => {
          if (val) {
            await this.changeAmountHandler(val);
          }
        });
      }
    }
  },
  beforeDestroy() {
    this.action.$off('change-tf-balance-to-user');
    this.action.$off('change-tf-balance-amount');
  },
  methods: {
    async changeUserHandler(val) {
      this.user_model = val;
    },
    async changeAmountHandler(val) {
      this.user_model.amount = val;
    },
    async saveData() {
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      this.user_model.amount = Number(this.user_model.amount);
      console.log(this.user_model)
      try {
        await PostRequest('transactions/tf-balance', this.user_model);
        this.overlay = this.$store.getters.isLoading;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        alert('Create transfer balance success');
        await this.$router.push({name: 'User'});
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Create data failed ' + e.message);
      }
    },
    async getUserData() {
      this.user_list = [];
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try {
        const response = await GetRequest('users');
        this.overlay = this.$store.getters.isLoading;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        this.user_list = response.data.filter(val => {
          return this.$store.getters.currentUser._id !== val._id;
        });
        // this.user_list = response.data;
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Create data failed ' + e.message);
      }
    },
    async getUserDataCurrent() {
      this.current_balance = 0;
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try {
        const response = await GetRequest('users/' + this.$store.getters.currentUser._id);
        this.overlay = this.$store.getters.isLoading;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        this.current_balance = response.data.current_balance;
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Create data failed ' + e.message);
      }
    }
  }
}
</script>

<style scoped>

</style>
