<template>
  <v-card-text>
    <v-row dense>
      <v-col
          cols="12"
      >
        <v-text-field
            dense
            v-model="current_balance"
            label="My Current Balance"
            :disabled="true"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-autocomplete
            dense
            label="To User"
            :items="user_list"
            item-text="full_name"
            clearable
            :rules="[v => !!v || 'To User must filled']"
            return-object
            @change="setUserModel"
        ></v-autocomplete>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="amount"
            type="number"
            label="Amount"
            @change="changeAmountHandler"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: "TfBalanceFormComponent",
  data: () => ({
    RequiredRules: [
      v => !!v || 'Field cannot empty',
    ],
    amount: 0,
  }),
  props: {
    user_model: Object,
    update: String,
    user_list: [],
    amt: Number,
    current_balance: Number,
  },
  methods: {
    setUserModel(val) {
      if (val) {
        this.action.$emit('change-tf-balance-to-user', val)
      }
      this.action.$emit('change-tf-balance-to-user', null)
    },
    changeAmountHandler() {
      // console.log(this.amount)
      this.action.$emit('change-tf-balance-amount', this.amount)
    }
  }
}
</script>

<style scoped>

</style>
